import { useHistory } from "react-router-dom"
import { useState, useEffect } from "react"
import { Row, Col, Card } from "react-bootstrap"
import { format } from "date-fns"
import PageTitle from "../../../components/PageTitle"
import { APICore } from "../../../helpers/api/apiCore"

import { FormInput } from "../../../components/"

const InvoiceList = () => {
  const baseUrl = "/invoice"
  const history = useHistory()
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [sort, setSort] = useState<any | null>(null)

  const [search, setSearch] = useState<string>("")

  useEffect(() => {
    setLoading(true)
    const api = new APICore()
    api
      .get(`${baseUrl}`, null)
      .then((res) => {
        setData(res.data.response)
        setLoading(false)
        console.log(res.data.response)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  const compare = (a: any, b: any) => {
    if (a < b) {
      return 1
    } else if (a > b) {
      return -1
    } else {
      return 0
    }
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Invoices", path: "/apps/invoices", active: true },
        ]}
        title={"Invoices List"}
      />
      <div
        className="page-title-box"
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <div />
        {JSON.parse(sessionStorage.getItem("user")!) &&
          JSON.parse(sessionStorage.getItem("user")!).access.invoice.add && (
            <div className="page-title-right">
              <div className="mt-md-0">
                <div
                  className="fs-18"
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    history.push("/apps/invoices/create")
                  }}
                >
                  <i className="uil-plus fs-24 m-2" />
                  New Invoice
                </div>
              </div>
            </div>
          )}
      </div>

      <Row
        style={{
          padding: "10px",
          alignContent: "center",
        }}
      >
        <FormInput
          style={{ width: "25%" }}
          type="text"
          name="search"
          className="form-control mb-4"
          placeholder="Search 'For' column.."
          key="search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          ID
        </Col>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={() => {
            if (sort && sort.field === "receiver") {
              if (sort.type === "asc") {
                setSort({
                  field: "receiver",
                  type: "desc",
                })
                data.sort((a: any, b: any) =>
                  compare(
                    (a.receiver.company
                      ? a.receiver.company
                      : a.receiver.contact
                    ).name,
                    (b.receiver.company
                      ? b.receiver.company
                      : b.receiver.contact
                    ).name
                  )
                )
              } else {
                setSort(null)
                data.sort((a: any, b: any) => compare(b.createdAt, a.createdAt))
              }
            } else {
              setSort({
                field: "receiver",
                type: "asc",
              })
              data.sort((a: any, b: any) =>
                compare(
                  (b.receiver.company ? b.receiver.company : b.receiver.contact)
                    .name,
                  (a.receiver.company ? a.receiver.company : a.receiver.contact)
                    .name
                )
              )
            }
          }}
        >
          For
          {sort &&
            sort.field === "receiver" &&
            (sort.type === "asc" ? (
              <i className="uil uil-arrow-up"></i>
            ) : (
              <i className="uil uil-arrow-down"></i>
            ))}
        </Col>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          Total Items
        </Col>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={() => {
            if (sort && sort.field === "total") {
              if (sort.type === "asc") {
                setSort({
                  field: "total",
                  type: "desc",
                })
                data.sort((a: any, b: any) => compare(a.total, b.total))
              } else {
                setSort(null)
                data.sort((a: any, b: any) => compare(b.createdAt, a.createdAt))
              }
            } else {
              setSort({
                field: "total",
                type: "asc",
              })
              data.sort((a: any, b: any) => compare(b.total, a.total))
            }
          }}
        >
          Total
          {sort &&
            sort.field === "total" &&
            (sort.type === "asc" ? (
              <i className="uil uil-arrow-up"></i>
            ) : (
              <i className="uil uil-arrow-down"></i>
            ))}
        </Col>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={() => {
            if (sort && sort.field === "afterDiscount") {
              if (sort.type === "asc") {
                setSort({
                  field: "afterDiscount",
                  type: "desc",
                })
                data.sort((a: any, b: any) =>
                  compare(a.afterDiscount, b.afterDiscount)
                )
              } else {
                setSort(null)
                data.sort((a: any, b: any) => compare(b.createdAt, a.createdAt))
              }
            } else {
              setSort({
                field: "afterDiscount",
                type: "asc",
              })
              data.sort((a: any, b: any) =>
                compare(b.afterDiscount, a.afterDiscount)
              )
            }
          }}
        >
          After discount
          {sort &&
            sort.field === "afterDiscount" &&
            (sort.type === "asc" ? (
              <i className="uil uil-arrow-up"></i>
            ) : (
              <i className="uil uil-arrow-down"></i>
            ))}
        </Col>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={() => {
            if (sort && sort.field === "dueDate") {
              if (sort.type === "asc") {
                setSort({
                  field: "dueDate",
                  type: "desc",
                })
                data.sort((a: any, b: any) => compare(a.dueDate, b.dueDate))
              } else {
                setSort(null)
                data.sort((a: any, b: any) => compare(b.createdAt, a.createdAt))
              }
            } else {
              setSort({
                field: "dueDate",
                type: "asc",
              })
              data.sort((a: any, b: any) => compare(b.dueDate, a.dueDate))
            }
          }}
        >
          Due Date
          {sort &&
            sort.field === "dueDate" &&
            (sort.type === "asc" ? (
              <i className="uil uil-arrow-up"></i>
            ) : (
              <i className="uil uil-arrow-down"></i>
            ))}
        </Col>
      </Row>
      {loading ? (
        <div className="spinner"></div>
      ) : (
        data.map((one: any, index: any) => {
          if (
            (one.receiver.company
              ? one.receiver.company
              : one.receiver.contact
            ).name
              .toLowerCase()
              .includes(search.toLowerCase())
          )
            // console.log(
            //   (one.receiver.company
            //     ? one.receiver.company
            //     : one.receiver.contact
            //   ).name
            //     .toLowerCase()
            //     .includes(search.toLowerCase())
            // )
            return (
              <Card
                key={index}
                style={{
                  cursor: "pointer",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <Row
                  style={{
                    padding: "10px",
                    alignContent: "center",
                  }}
                >
                  <>
                    <Col
                      onClick={() =>
                        history.push(`/apps/invoices/details/${one._id}`)
                      }
                    >
                      {one._id}
                    </Col>
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                      onClick={() =>
                        history.push(`/apps/invoices/details/${one._id}`)
                      }
                    >
                      {
                        (one.receiver.company
                          ? one.receiver.company
                          : one.receiver.contact
                        ).name
                      }
                    </Col>
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                      onClick={() =>
                        history.push(`/apps/invoices/details/${one._id}`)
                      }
                    >
                      {one.items.length}
                    </Col>
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                      onClick={() =>
                        history.push(`/apps/invoices/details/${one._id}`)
                      }
                    >
                      {" "}
                      {one.total}
                    </Col>
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                      onClick={() =>
                        history.push(`/apps/invoices/details/${one._id}`)
                      }
                    >
                      {" "}
                      {one.afterDiscount}
                    </Col>
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                      onClick={() =>
                        history.push(`/apps/invoices/details/${one._id}`)
                      }
                    >
                      {format(new Date(one.dueDate), "dd/MM/yyyy")}
                    </Col>
                  </>
                </Row>
              </Card>
            )
        })
      )}
      {/* <Row>
				<Table
					columns={columns}
					data={data}
					pageSize={5}
					sizePerPageList={sizePerPageList}
					isSortable={true}
					pagination={true}
					isSelectable={false}
					isSearchable={true}
					OffcanvasPlacement={OffcanvasPlacement}
					handleChange={handleChange}
				/>
			</Row> */}
    </>
  )
}

export default InvoiceList
