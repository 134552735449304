import { useHistory } from "react-router-dom"
import { useState, useEffect } from "react"
import { Row, Col, Card } from "react-bootstrap"
import { format } from "date-fns"
import PageTitle from "../../../components/PageTitle"
import { APICore } from "../../../helpers/api/apiCore"
import AddEditCampaign from "../UserDetails/AddEditCampaign"
import AddChannel from "./AddChannel"

import { FormInput } from "../../../components/"

const CampaignList = () => {
  const baseUrl = "/campaign"
  const history = useHistory()
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [sort, setSort] = useState<any | null>(null)

  const [search, setSearch] = useState<string>("")

  useEffect(() => {
    setLoading(true)
    const api = new APICore()
    api
      .get(`${baseUrl}`, null)
      .then((res) => {
        setData(res.data)
        setLoading(false)
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  const compare = (a: any, b: any) => {
    if (a < b) {
      return 1
    } else if (a > b) {
      return -1
    } else {
      return 0
    }
  }
  const handleChange = (type: string, values: any) => {
    let newState
    if (type === "Edit") {
      newState = data.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj._id === values.id) {
          return { ...obj, ...values }
        }

        // 👇️ otherwise return object as is
        return obj
      })
    } else if (type === "Delete") {
      newState = data.filter((obj: any) => obj._id !== values.id)
    } else if (type === "Add") {
      newState = data
      newState.push(values)
      window.location.reload()
    }
    setData([...newState])
  }

  const removeItem = (id: string) => {
    if (window.confirm("Are you sure you wish to delete this user?")) {
      const api = new APICore()
      api
        .delete(`${baseUrl}/deleteUser/${id}`)
        .then((response) => {
          handleChange("Delete", { id })
        })
        .catch((e) => {})
    }
  }
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Campaign", path: "/apps/Campaign", active: true },
        ]}
        title={"Campaign List"}
      />
      <div
        className="page-title-box"
        style={{
          display: "flex",
          alignItems: "flex-end",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        <div />
        {JSON.parse(sessionStorage.getItem("user")!).access.users.add && (
          <div className="page-title-right">
            <div className="mt-md-0">
              <AddEditCampaign isEditable={false} handleChange={handleChange} />
            </div>
          </div>
        )}
        {JSON.parse(sessionStorage.getItem("user")!).access.users.add && (
          <div className="page-title-right">
            <div className="mt-md-0">
              <AddChannel isEditable={false} handleChange={handleChange} />
            </div>
          </div>
        )}
        {/* {JSON.parse(sessionStorage.getItem("user")!) &&
                    JSON.parse(sessionStorage.getItem("user")!).access.invoice
                        .add && (
                        <div className="page-title-right">
                            <div className="mt-md-0">
                                <div
                                    className="fs-18"
                                    style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                        history.push("/apps/invoices/create")
                                    }}
                                >
                                    <i className="uil-plus fs-24 m-2" />
                                    New Campaign
                                </div>
                            </div>
                        </div>
                    )} */}
      </div>

      <Row
        style={{
          padding: "10px",
          alignContent: "center",
        }}
      >
        <FormInput
          style={{ width: "25%" }}
          type="text"
          name="search"
          className="form-control mb-4"
          placeholder="Search 'For' column.."
          key="search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          ID
        </Col>
        {/* <Col
                    style={{
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "16px",
                    }}
                    onClick={() => {
                        if (sort && sort.field === "receiver") {
                            if (sort.type === "asc") {
                                setSort({
                                    field: "receiver",
                                    type: "desc",
                                })
                                data.sort((a: any, b: any) =>
                                    compare(
                                        (a.title
                                            ? a.title
                                            : a.title
                                        ).title,
                                        (b.title
                                            ? b.title
                                            : b.title
                                        ).title
                                    )
                                )
                            } else {
                                setSort(null)
                                data.sort((a: any, b: any) =>
                                    compare(b.createdAt, a.createdAt)
                                )
                            }
                        } else {
                            setSort({
                                field: "receiver",
                                type: "asc",
                            })
                            data.sort((a: any, b: any) =>
                                compare(
                                    (b.receiver.company
                                        ? b.receiver.company
                                        : b.receiver.contact
                                    ).name,
                                    (a.receiver.company
                                        ? a.receiver.company
                                        : a.receiver.contact
                                    ).name
                                )
                            )
                        }
                    }}
                >
                    For
                    {sort &&
                        sort.field === "receiver" &&
                        (sort.type === "asc" ? (
                            <i className="uil uil-arrow-up"></i>
                        ) : (
                            <i className="uil uil-arrow-down"></i>
                        ))}
                </Col> */}
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          Title
        </Col>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          Channel
        </Col>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={() => {
            if (sort && sort.field === "budget") {
              if (sort.type === "asc") {
                setSort({
                  field: "budget",
                  type: "desc",
                })
                data.sort((a: any, b: any) => compare(a.budget, b.budget))
              } else {
                setSort(null)
                data.sort((a: any, b: any) => compare(b.createdAt, a.createdAt))
              }
            } else {
              setSort({
                field: "budget",
                type: "asc",
              })
              data.sort((a: any, b: any) => compare(b.budget, a.budget))
            }
          }}
        >
          Budget
        </Col>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={() => {
            if (sort && sort.field === "user") {
              if (sort.type === "asc") {
                setSort({
                  field: "user",
                  type: "desc",
                })
                data.sort((a: any, b: any) => compare(a.user, b.user))
              } else {
                setSort(null)
                data.sort((a: any, b: any) => compare(b.createdAt, a.createdAt))
              }
            } else {
              setSort({
                field: "user",
                type: "asc",
              })
              data.sort((a: any, b: any) => compare(b.user, a.user))
            }
          }}
        >
          contacts submitted
        </Col>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={() => {
            if (sort && sort.field === "createdAt") {
              if (sort.type === "asc") {
                setSort({
                  field: "createdAt",
                  type: "desc",
                })
                data.sort((a: any, b: any) => compare(a.createdAt, b.createdAt))
              } else {
                setSort(null)
                data.sort((a: any, b: any) => compare(b.createdAt, a.createdAt))
              }
            } else {
              setSort({
                field: "createdAt",
                type: "asc",
              })
              data.sort((a: any, b: any) => compare(b.createdAt, a.createdAt))
            }
          }}
        >
          Due Date
          {sort &&
            sort.field === "dueDate" &&
            (sort.type === "asc" ? (
              <i className="uil uil-arrow-up"></i>
            ) : (
              <i className="uil uil-arrow-down"></i>
            ))}
        </Col>
      </Row>
      {loading ? (
        <div className="spinner"></div>
      ) : (
        data.map((one: any, index: any) => {
          if (
            one.title.toLowerCase().includes(search.toLowerCase()) ||
            one.channel[0].name.toLowerCase().includes(search.toLowerCase())
          )
            return (
              <Card
                key={index}
                style={{
                  // cursor: "pointer",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <Row
                  style={{
                    padding: "10px",
                    alignContent: "center",
                  }}
                >
                  <>
                    <Col
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(`/landingCampaign/${one._id}`)
                      }
                    >
                      {one._id}
                    </Col>
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {one.title}
                    </Col>
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {one.channel[0].name}
                    </Col>
                    {/* <Col
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                       
                                    >
                                        {one.items.length}
                                    </Col> */}
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {" "}
                      {one.budget}
                    </Col>
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {" "}
                      {one.user}
                    </Col>
                    <Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {format(new Date(one.createdAt), "dd/MM/yyyy")}
                    </Col>
                  </>
                </Row>
              </Card>
            )
        })
      )}
      {/* <Row>
				<Table
					columns={columns}
					data={data}
					pageSize={5}
					sizePerPageList={sizePerPageList}
					isSortable={true}
					pagination={true}
					isSelectable={false}
					isSearchable={true}
					OffcanvasPlacement={OffcanvasPlacement}
					handleChange={handleChange}
				/>
			</Row> */}
    </>
  )
}

export default CampaignList
