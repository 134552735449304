import React from "react"
import { Route, RouteProps } from "react-router-dom"
import ContactDetails from "../pages/apps/ContactDetails"
import DaysOffRequests from "../pages/apps/DaysOffRequests"
import InvoiceList from "../pages/apps/Invoice"
import InvoiceDetails from "../pages/apps/Invoice/InvoiceDetails"
import QuotationList from "../pages/apps/Quotation"
import QuotationDetails from "../pages/apps/Quotation/QuotationDetails"
import LogInOut from "../pages/apps/LogInOut"
import SurveyList from "../pages/apps/SurveyList"
import TodoList from "../pages/apps/TodoList"
import CampaignList from "../pages/apps/Campaign"
// import Quotation from "../pages/apps/Quotation"
// components
import PrivateRoute from "./PrivateRoute"
import Root from "./Root"

// lazy load all the views

//campaing landing
const CampaignLanding = React.lazy(
  () => import("../pages/apps/CampaignLanding")
)
// auth
const Login = React.lazy(() => import("../pages/auth/Login"))
const Logout = React.lazy(() => import("../pages/auth/Logout"))
const Confirm = React.lazy(() => import("../pages/auth/Confirm"))
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"))
const NewPassword = React.lazy(() => import("../pages/auth/NewPassword"))

const Register = React.lazy(() => import("../pages/auth/Register"))
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"))

// landing
const Landing = React.lazy(() => import("../pages/landing/"))

// dashboard
const EcommerceDashboard = React.lazy(
  () => import("../pages/dashboard/Ecommerce/")
)
const AnalyticsDashboard = React.lazy(
  () => import("../pages/dashboard/Analytics/")
)

const ContactsList = React.lazy(() => import("../pages/apps/Contacts"))
const UsersList = React.lazy(() => import("../pages/apps/Users/index"))
const UserDetails = React.lazy(() => import("../pages/apps/UserDetails/"))
const Search = React.lazy(() => import("../pages/apps/Search/"))
// Company
const CompanyList = React.lazy(() => import("../pages/apps/Company/index"))
const CompanyDetails = React.lazy(
  () => import("../pages/apps/CompanyDetails/index")
)

const InvoiceTemp = React.lazy(() => import("../pages/apps/Invoice/Invoice"))
const QuotationTemp = React.lazy(
  () => import("../pages/apps/Quotation/Quotation")
)
// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"))
const Projects = React.lazy(() => import("../pages/apps/Projects/"))
const ProjectDetail = React.lazy(() => import("../pages/apps/Projects/Detail/"))
// - crm
const CRM = React.lazy(() => import("../pages/apps/CRM/list"))
const OpportunityDetails = React.lazy(() => import("../pages/apps/CRM/details"))
// - chat
const ChatApp = React.lazy(() => import("../pages/apps/Chat/"))
// - email
const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"))
const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"))
const EmailCompose = React.lazy(() => import("../pages/apps/Email/Compose"))
// - tasks
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"))
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"))
// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"))

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"))
const Error401 = React.lazy(() => import("../pages/error/Error401"))
const Error500 = React.lazy(() => import("../pages/error/Error500"))
// -other
const Starter = React.lazy(() => import("../pages/other/Starter"))
const Profile = React.lazy(() => import("../pages/apps/Profile"))
const Activity = React.lazy(() => import("../pages/other/Activity"))
const Invoice = React.lazy(() => import("../pages/other/Invoice"))
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"))
const Pricing = React.lazy(() => import("../pages/other/Pricing"))

// uikit
const UIElements = React.lazy(() => import("../pages/uikit"))

// widgets
const Widgets = React.lazy(() => import("../pages/widgets/"))

// icons
const Unicons = React.lazy(() => import("../pages/icons/Unicons"))
const FeatherIcons = React.lazy(() => import("../pages/icons/Feather/"))
const BootstrapIcon = React.lazy(() => import("../pages/icons/Bootstrap/"))

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"))
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"))
const FormValidation = React.lazy(() => import("../pages/forms/Validation"))
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"))
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"))
const Editors = React.lazy(() => import("../pages/forms/Editors"))

// charts
const Charts = React.lazy(() => import("../pages/charts/"))

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"))
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"))

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"))
const VectorMaps = React.lazy(() => import("../pages/maps/VectorMaps"))

export interface RoutesProps {
  path: RouteProps["path"]
  name?: string
  component?: RouteProps["component"]
  route?: any
  exact?: RouteProps["exact"]
  icon?: string
  header?: string
  roles?: string[]
  children?: RoutesProps[]
}

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  component: () => <Root />,
  route: Route,
}

// const campaignLandingRoute: RoutesProps = {
// 	path: "/campaignLanding/:id",
// 	exact: true,
// 	component: () => <CampaignLanding />,
// 	route: Route,
// };

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/dashboard/ecommerce",
      name: "Ecommerce",
      component: EcommerceDashboard,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: AnalyticsDashboard,
      route: PrivateRoute,
    },
  ],
}

const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  component: CalendarApp,
  header: "Apps",
}

const InvoiceAppRoutes: RoutesProps = {
  path: "/apps/invoices",
  name: "Invoice",
  // route: PrivateRoute,
  // roles: ["Admin"],
  icon: "file-text",
  header: "Apps",
  children: [
    {
      path: "/apps/invoices/list",
      name: "Invoice List",
      component: InvoiceList,
      route: PrivateRoute,
    },
    {
      path: "/apps/invoices/details/:id",
      name: "Invoice Details",
      component: InvoiceDetails,
      route: PrivateRoute,
    },
    {
      path: "/apps/invoices/create",
      name: "Create Invoice",
      component: InvoiceTemp,
      route: PrivateRoute,
    },
  ],
}

const QuotationAppRoutes: RoutesProps = {
  path: "/apps/quotation",
  name: "Quotation",
  // route: PrivateRoute,
  // roles: ["Admin"],
  icon: "file-text",
  header: "Apps",
  children: [
    {
      path: "/apps/quotation/list",
      name: "Quotation List",
      component: QuotationList,
      route: PrivateRoute,
    },
    {
      path: "/apps/quotation/details/:id",
      name: "Quotation Details",
      component: QuotationDetails,
      route: PrivateRoute,
    },
    {
      path: "/apps/quotation/create",
      name: "Create Quotation",
      component: QuotationTemp,
      route: PrivateRoute,
    },
  ],
}

const CRMAppRoutes: RoutesProps = {
  path: "/apps/CRM",
  name: "CRM",
  // route: PrivateRoute,
  // roles: ["Admin"],
  icon: "CRM",
  // component: CRM,
  header: "Apps",
  children: [
    {
      path: "/apps/CRM/list",
      name: "Opportunity List",
      component: CRM,
      route: PrivateRoute,
    },
    {
      path: "/apps/CRM/details/:id",
      name: "Opportunity Details",
      component: OpportunityDetails,
      route: PrivateRoute,
    },
  ],
}

const CompanyAppRoutes: RoutesProps = {
  path: "/apps/company",
  name: "company",
  icon: "company",
  header: "Apps",
  children: [
    {
      path: "/apps/company/list",
      name: "Company List",
      component: CompanyList,
      route: PrivateRoute,
    },
    {
      path: "/apps/company/details/:id",
      name: "Company Details",
      component: CompanyDetails,
      route: PrivateRoute,
    },
  ],
}

const ContactsAppRoutes: RoutesProps = {
  path: "/apps/contacts",
  name: "Contacts",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "contact",
  component: ContactsList,
  header: "Apps",
}

const UsersAppRoutes: RoutesProps = {
  path: "/apps/users/list",
  name: "Users",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "user",
  component: UsersList,
  header: "Apps",
}
const TodoAppRoutes: RoutesProps = {
  path: "/apps/todo/list",
  name: "Todo Lists",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "list",
  component: TodoList,
  header: "Apps",
}

const UserDetailsAppRoutes: RoutesProps = {
  path: "/apps/users/details/:id",
  name: "UserDetails",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "user",
  component: UserDetails,
  header: "Apps",
}

const ContactDetailsAppRoutes: RoutesProps = {
  path: "/apps/contact/:id",
  name: "ContactDetails",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "users",
  component: ContactDetails,
  header: "Apps",
}

const chatAppRoutes: RoutesProps = {
  path: "/apps/chat",
  name: "Chat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "message-square",
  component: ChatApp,
}

const emailAppRoutes: RoutesProps = {
  path: "/apps/email",
  name: "Email",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/apps/email/inbox",
      name: "Inbox",
      component: Inbox,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/details",
      name: "Email Details",
      component: EmailDetail,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/compose",
      name: "Compose Email",
      component: EmailCompose,
      route: PrivateRoute,
    },
  ],
}

const projectAppRoutes: RoutesProps = {
  path: "/apps/projects",
  name: "Projects",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-briefcase",

  children: [
    {
      path: "/apps/projects/list",
      name: "List",
      component: Projects,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/details/:id",
      name: "Detail",
      component: ProjectDetail,
      route: PrivateRoute,
    },
  ],
}

const taskAppRoutes: RoutesProps = {
  path: "/apps/tasks",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: "/apps/tasks/list",
      name: "Task List",
      component: TaskList,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/kanban/:proj",
      name: "Kanban",
      component: Kanban,
      route: PrivateRoute,
    },
  ],
}

const fileAppRoutes: RoutesProps = {
  path: "/apps/file-manager",
  name: "File Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  component: FileManager,
}

const SearchRoutes: RoutesProps = {
  path: "/search/:search",
  name: "Search",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "search",
  component: Search,
  header: "Apps",
}

const SurveyAppRoutes: RoutesProps = {
  path: "/apps/survey/list",
  name: "Surveys",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "check-circle",
  component: SurveyList,
  header: "Apps",
}

const campaignAppRoutes: RoutesProps = {
  path: "/apps/campaign/list",
  name: "campaign",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "check-circle",
  component: CampaignList,
  header: "Apps",
}
const DaysOffRequestsAppRoutes: RoutesProps = {
  path: "/apps/daysoffrequests/list",
  name: "Days Off Requests",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "pause",
  component: DaysOffRequests,
  header: "Apps",
}

const LogInOutAppRoutes: RoutesProps = {
  path: "/apps/loginout/list",
  name: "Log In/Out Records",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "log-in",
  component: LogInOut,
  header: "Apps",
}

const appRoutes = [
  calendarAppRoutes,
  chatAppRoutes,
  emailAppRoutes,
  projectAppRoutes,
  taskAppRoutes,
  fileAppRoutes,
  CompanyAppRoutes,
  SearchRoutes,
  SurveyAppRoutes,
  DaysOffRequestsAppRoutes,
  QuotationAppRoutes,
  campaignAppRoutes,
  LogInOutAppRoutes,
]

// pages
const extrapagesRoutes: RoutesProps = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      component: Starter,
      route: PrivateRoute,
    },
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile,
      route: PrivateRoute,
    },
    {
      path: "/pages/activity",
      name: "Activity",
      component: Activity,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
      route: PrivateRoute,
    },
  ],
}

// ui
const uiRoutes: RoutesProps = {
  path: "/components",
  name: "Components",
  icon: "package",
  header: "UI Elements",
  children: [
    {
      path: "/components/ui-elements",
      name: "UIElements",
      component: UIElements,
      route: PrivateRoute,
    },
    {
      path: "/components/widgets",
      name: "Widgets",
      component: Widgets,
      route: PrivateRoute,
    },
    {
      path: "/icons",
      name: "Icons",
      children: [
        {
          path: "/icons/unicons",
          name: "Unicons",
          component: Unicons,
          route: PrivateRoute,
        },
        {
          path: "/icons/feather",
          name: "Feather",
          component: FeatherIcons,
          route: PrivateRoute,
        },
        {
          path: "/icons/bootstrap",
          name: "Bootstrap Icon",
          component: BootstrapIcon,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/forms",
      name: "Forms",
      children: [
        {
          path: "/forms/basic",
          name: "Basic Elements",
          component: BasicForms,
          route: PrivateRoute,
        },
        {
          path: "/forms/advanced",
          name: "Form Advanced",
          component: FormAdvanced,
          route: PrivateRoute,
        },
        {
          path: "/forms/validation",
          name: "Form Validation",
          component: FormValidation,
          route: PrivateRoute,
        },
        {
          path: "/forms/wizard",
          name: "Form Wizard",
          component: FormWizard,
          route: PrivateRoute,
        },
        {
          path: "/forms/upload",
          name: "File Upload",
          component: FileUpload,
          route: PrivateRoute,
        },
        {
          path: "/forms/editors",
          name: "Editors",
          component: Editors,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/components/charts",
      name: "Charts",
      component: Charts,
      route: PrivateRoute,
    },
    {
      path: "/tables",
      name: "Tables",
      children: [
        {
          path: "/tables/basic",
          name: "Basic",
          component: BasicTables,
          route: PrivateRoute,
        },
        {
          path: "/tables/advanced",
          name: "Advanced",
          component: AdvancedTables,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/maps",
      name: "Maps",
      children: [
        {
          path: "/maps/googlemaps",
          name: "Google Maps",
          component: GoogleMaps,
          route: PrivateRoute,
        },
        {
          path: "/maps/vectorMaps",
          name: "Google Maps",
          component: VectorMaps,
          route: PrivateRoute,
        },
      ],
    },
  ],
}

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    component: Register,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/auth/newPassword",
    name: "New Password",
    component: NewPassword,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
]

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/landing",
    name: "landing",
    component: Landing,
    route: Route,
  },
  {
    path: "/landingCampaign/:id",
    name: "Campaign",
    component: CampaignLanding,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    component: Error404,
    route: Route,
  },
  {
    path: "/unauthorized",
    name: "Error - 401",
    component: Error401,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    component: Error500,
    route: Route,
  },
]

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = []

  routes = routes || []
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item)

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    }
  })
  return flatRoutes
}

// All routes
const authProtectedRoutes = [
  rootRoute,
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
  ContactsAppRoutes,
  UsersAppRoutes,
  TodoAppRoutes,
  UserDetailsAppRoutes,
  ContactDetailsAppRoutes,
  CRMAppRoutes,
  InvoiceAppRoutes,
]
const publicRoutes = [...authRoutes, ...otherPublicRoutes]

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes])
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes])
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
}
