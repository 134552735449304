import { useState, useEffect, useRef } from "react"
import { Row, Col, Card, Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import ReactToPrint from "react-to-print"

import PageTitle from "../../../components/PageTitle"

import { invoiceData } from "./data"

import { format } from "date-fns"
import logoDark from "../../../assets/images/logo-dark.png"
import logoLight from "../../../assets/images/logo-light.png"

import { APICore } from "../../../helpers/api/apiCore"
import { useParams } from "react-router-dom"

const InvoiceDetails = () => {
  const { id } = useParams<{ id: string }>()

  const [invoice, setInvoice] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const api = new APICore()
    setLoading(true)
    api
      .get(`/invoice/${id}`, null)
      .then((res) => {
        setInvoice(res.data.response)
        setLoading(false)
      })
      .catch((err) => {})
  }, [id])

  const ComponentToPrint = () => (
    <Row>
      <Col xs={12}>
        <Card style={{ width: "700px" }}>
          <Card.Body>
            <div className="clearfix">
              <div className="auth-logo mt-4">
                <Link to="/" className="logo logo-dark ">
                  <span
                    className="logo-lg mt-2 mb-2"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img src={logoDark} alt="" height="50" />
                  </span>
                </Link>
                <Link to="/" className="logo logo-light ">
                  <span className="logo-lg mt-2 mb-2">
                    <img src={logoLight} alt="" height="50" />
                  </span>
                </Link>
              </div>
              <Row>
                <Col>
                  <div>
                    <h6 className="fs-16 mt-4">{"Neon"}</h6>
                    <address className="mt-2 fs-12">
                      73, Nabil El Wakad, Ard El Golf
                      <br />
                      Cairo, Egypt, 11511
                      {/* {invoiceData.address.city}, {invoiceData.address.state}{" "}
	{invoiceData.address.zip} */}
                      <br />
                      01000000000
                      <br />
                      Tax: 182365120
                      <br />
                      Commercial Record: 12986dassjd2
                    </address>
                  </div>
                </Col>
                <Col style={{ textAlign: "end" }}>
                  {/* <h6 className="fw-normal">Invoice For :</h6> */}
                  <h6 className="fs-16 mt-4">
                    {invoice.receiver.company
                      ? invoice.receiver.company.name
                      : invoice.receiver.contact
                      ? invoice.receiver.contact.name
                      : "Receiver"}
                  </h6>
                  {invoice.receiver.company ? (
                    <address className="mt-2 fs-12">
                      {invoice.receiver.company
                        ? invoice.receiver.company.address.address
                        : "Address"}
                      <br />
                      {invoice.receiver.company
                        ? invoice.receiver.company.address.city +
                          ", " +
                          invoice.receiver.company.address.country +
                          ", " +
                          invoice.receiver.company.address.zip
                        : ""}
                      <br />
                      {/* <abbr title="Phone">P:</abbr>{" "} */}

                      {/* {invoice.receiver.company.phone
                        ? invoice.receiver.company.phone
                        : "Phone Number"} */}
                      <br />
                      {invoice.receiver.company
                        ? `Tax: ${invoice.receiver.company.taxId}`
                        : "Tax ID"}
                      <br />
                      {invoice.receiver.company
                        ? `Commercial Record: ${invoice.receiver.company.commercialRecord}`
                        : "Commercial Record"}
                    </address>
                  ) : (
                    <address className="mt-2 fs-12">
                      {invoice.receiver.contact
                        ? invoice.receiver.contact.email
                        : "Email"}
                      <br />
                      {invoice.receiver.contact
                        ? invoice.receiver.contact.ID
                        : "National ID / Passport ID"}
                      <br />
                      {invoice.receiver.contact
                        ? invoice.receiver.contact.phone
                        : "Phone Number"}
                    </address>
                  )}
                </Col>
              </Row>
            </div>

            <Row className="mt-2">
              <Col>
                <div>
                  <Col>
                    <Row className="row fs-12">
                      <Col xs={2}>Invoice Date :</Col>
                      <Col>
                        {format(new Date(invoice.createdAt), "dd/MM/yyyy")}
                      </Col>
                    </Row>
                    <Row className="row fs-12">
                      <Col xs={2}>Print Date :</Col>
                      <Col>{format(new Date(), "dd/MM/yyyy hh:mm a")}</Col>
                    </Row>
                    <Row className="row mb-2 fs-12">
                      <Col xs={2}>Due Date :</Col>
                      <Col>
                        {format(new Date(invoice.dueDate), "dd/MM/yyyy")}
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <div className="table-responsive">
                  <table className="table mt-2 table-centered  table-nowrap ">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th style={{ width: "25%" }}>Category</th>
                        <th style={{ width: "10%" }}>Cost</th>
                        <th style={{ width: "10%" }}>Quantity</th>
                        <th style={{ width: "10%" }} className="text-end">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(invoice.items || []).map((item: any, idx: any) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                              {/* <h5 className="fs-16 mt-0 mb-2"> */}
                              {item.name}
                              {/* </h5> */}
                            </td>
                            <td>
                              {/* <h5 className="fs-16 mt-0 mb-2"> */}
                              {item.category}
                              {/* </h5> */}
                              {/* <p className="text-muted mb-0">
				  {item.description}
				</p> */}
                            </td>
                            <td>
                              {/* <h5 className="fs-16 mt-0 mb-2"> */}
                              {item.cost}
                              {/* </h5> */}
                            </td>
                            <td>
                              {/* <h5 className="fs-16 mt-0 mb-2"> */}
                              {item.quantity}
                              {/* </h5> */}
                            </td>
                            <td className="text-end">
                              {item.cost * item.quantity}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
            <div className="clearfix">
              <h6 className="text-muted">Notes:</h6>
              <small className="text-muted">{invoiceData.notes}</small>
            </div>
            <Col className="float-end mt-4" style={{ width: "40%" }}>
              <Row>
                <Col xs={4}>
                  <span className="fw-medium">Sub-total :</span>
                </Col>
                <Col xs={7}>{` ${invoice.total}`} EGP</Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <span className="fw-medium">Discount :</span>
                </Col>
                <Col xs={7}>{invoice.discount}%</Col>
              </Row>
              <Row style={{ alignItems: "center" }}>
                <Col xs={4}>
                  <span className="fw-medium">Total :</span>
                </Col>
                <Col xs={7}>
                  <h3>
                    {(invoice.total - invoice.total * (invoice.discount / 100) <
                    0
                      ? 0
                      : invoice.total -
                        invoice.total * (invoice.discount / 100)) %
                      1 !==
                    0
                      ? (invoice.total -
                          invoice.total * (invoice.discount / 100) <
                        0
                          ? 0
                          : invoice.total -
                            invoice.total * (invoice.discount / 100)
                        ).toFixed(2)
                      : invoice.total -
                          invoice.total * (invoice.discount / 100) <
                        0
                      ? 0
                      : invoice.total -
                        invoice.total * (invoice.discount / 100)}{" "}
                    EGP
                  </h3>
                </Col>
              </Row>
            </Col>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )

  const componentRef = useRef<null | HTMLParagraphElement>(null)

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Invoices", path: "/apps/invoices/list" },
          {
            label: "Invoice Details",
            path: "/apps/invoices/details",
            active: true,
          },
        ]}
        title={"Invoice Details"}
      />
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div>
          <ReactToPrint
            trigger={() => (
              <Button onClick={() => window.print()}>Print</Button>
            )}
            content={() => componentRef.current}
          />
          <div
            ref={componentRef}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>
              <strong style={{ color: "red", fontSize: "16px" }}>
                Disclaimer:{" "}
              </strong>
              <span style={{ color: "red" }}>
                This inovoice is added to the database it can't be edited nor
                delete
              </span>
            </p>
            <ComponentToPrint />
          </div>
        </div>
      )}
    </>
  )
}

export default InvoiceDetails
