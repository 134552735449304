import { useState, useRef, ChangeEvent, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Row, Col, Button, Offcanvas, Alert, Form } from "react-bootstrap"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
// components
import { FormInput } from "../../../components"

import { APICore } from "../../../helpers/api/apiCore"
import axios from "axios"
import { ChannelTypes } from "./data"

const api = new APICore()
const baseUrl = "/user"

const AddChannel = ({
  isEditable,
  campaign,
  handleChange,
}: {
  isEditable: boolean
  campaign?: ChannelTypes
  handleChange: (type: string, values: any) => void
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [file, setFile] = useState<any>()
  const [preview, setPreview] = useState<any>()
  const hiddenFileInput = useRef<null | HTMLInputElement>(null)
  const [error, setError] = useState<string | null>()
  const errorRef = useRef<null | HTMLDivElement>(null)

  const userMe = JSON.parse(sessionStorage.getItem("user")!)

  /**
   * handle modal toggle
   */
  const toggle = () => {
    setShow((prevState) => !prevState)
  }

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter the title"),
      link: yup.string(),
    })
  )

  const methods = useForm({
    defaultValues: campaign,
    resolver: schemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods

  const onSubmitEvent = async (data: any) => {
    // e.preventDefault()
    // console.log(e.target)

    setError(null)

    let body = {
      name: data.name,
      link: data.link ? data.link : " ",
    }
    // let bodyFormData = new FormData()
    // bodyFormData.append("file", file, file?.name)
    // setLoading(true)
    // axios
    //     .post(`/data/upload`, bodyFormData)
    //     .then((response) => {
    // body = {
    // ...body,
    // ...{
    //     image: response.data.url,
    // },
    // }
    console.log(body)
    axios
      .post(`/channel/addChannel`, body, {
        headers: {
          Authorization: JSON.parse(sessionStorage.getItem("user")!).token,
        },
      })
      .then((response) => {
        console.log(response)
        setLoading(false)
        toggle()
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)

        setError(e)
        setLoading(false)
        errorRef.current?.scrollIntoView({
          behavior: "smooth",
        })
      })
    // })
    // .catch((e) => {
    //     console.log(e)
    //     setLoading(false)
    //     errorRef.current?.scrollIntoView({ behavior: "smooth" })
    // })
    // }

    // isEditable ? onUpdateEvent(body) : onAddEvent(body);
  }

  return (
    <>
      <div
        className="fs-18"
        style={{ cursor: "pointer", fontWeight: "bold" }}
        onClick={() => {
          userMe.access.users.add && toggle()
        }}
      >
        <i className="uil-plus fs-24 m-2" />
        New Channel
      </div>
      <Offcanvas
        show={show}
        onHide={toggle}
        placement={"end"}
        style={{ width: "600px" }}
      >
        <Offcanvas.Header closeButton>
          <h5 id="offcanvasTopLabel">Channel</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {error && (
            <div ref={errorRef}>
              <Alert variant="danger" className="my-2">
                {error}
              </Alert>
            </div>
          )}

          <form
            // noValidate
            name="chat-form"
            id="chat-form"
            onSubmit={handleSubmit(onSubmitEvent)}
          >
            <Row>
              <Col sm={6}>
                <FormInput
                  type="text"
                  label="name"
                  name="name"
                  className="form-control"
                  placeholder="Insert name"
                  containerClass={"mb-3"}
                  register={register}
                  key="name"
                  errors={errors}
                  control={control}
                />
              </Col>

              <Col sm={6}>
                <FormInput
                  type="text"
                  label="link"
                  name="link"
                  className="form-control"
                  placeholder="Insert link"
                  containerClass={"mb-3"}
                  register={register}
                  key="link"
                  errors={errors}
                  control={control}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={16} className="text-center">
                {loading ? (
                  <div className="spinner"></div>
                ) : (
                  <Button
                    variant="success"
                    type="submit"
                    className="btn btn-success"
                    style={{ width: "100px" }}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default AddChannel
