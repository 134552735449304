import { APICore } from "./apiCore"

const api = new APICore()

// account
function login(params: { email: string; password: string }) {
    const baseUrl = "/auth/signin"
    // axios
    // 	.post(baseUrl, params)
    // 	.then((response) => {
    // 		console.log(response)
    // 	})
    // 	.catch((e) => {
    // 		console.log(e)
    // 	})
    return api.create(`${baseUrl}`, params)
}

function logout() {
    const baseUrl = "/loginout/logOut"
    console.log(baseUrl)
    return api.updatePatch(baseUrl, {})
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = "/register/"
    return api.create(`${baseUrl}`, params)
}

function forgotPassword(params: { email: string }) {
    const baseUrl = "/forget-password/"
    return api.create(`${baseUrl}`, params)
}
window.close  = function() {
    logout();
};
export { login, signup, forgotPassword, logout }
