import { useState, useEffect } from "react"
import { Row, Col, Card, Button, Form } from "react-bootstrap"
import { APICore } from "../../../helpers/api/apiCore"
import { FormInput } from "../../../components"
import PageTitle from "../../../components/PageTitle"
import { EditText } from "react-edit-text"

const TodoList = () => {
  const baseUrl = "/user"
  const [search, setSearch] = useState<String>("")
  const [todolists, setTodolists] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [showSave, setShowSave] = useState<boolean>(false)

  useEffect(() => {
    const api = new APICore()
    if (todolists.length === 0) {
      setLoading(true)
      api
        .get(`${baseUrl}/getTodoList`, null)
        .then((res) => {
          setTodolists(res.data.response.todolists)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = () => {
    setSaving(true)
    console.log(todolists)
    const api = new APICore()
    api
      .updatePatch(`${baseUrl}/updateTodoList`, { todolists })
      .then((response) => {
        setSaving(false)
        setShowSave(false)
      })
      .catch((err) => {
        setSaving(false)
        console.log(err)
      })
  }

  const handleAdd = () => {
    var todo = [{ title: "New Todo List", tasks: [] }, ...todolists]
    setTodolists(todo)
    if (!showSave) setShowSave(true)
  }

  const handleDelete = (i: number) => {
    setTodolists([])
    var todo = [...todolists]
    todo.splice(i, 1)
    setTodolists(todo)
    if (!showSave) setShowSave(true)
  }

  const handleChangeName = (value: any, i: number) => {
    var todo = [...todolists]
    todo[i].title = value
    console.log(todo)
    setTodolists([...todo])
    if (!showSave) setShowSave(true)
  }

  const handleAddTask = (i: number) => {
    var todo = [...todolists]
    todo[i].tasks.push({ text: "New Task", done: false })
    setTodolists(todo)
    if (!showSave) setShowSave(true)
  }

  const handleChangeTaskName = (value: any, i: number, i2: number) => {
    var todo = [...todolists]
    todo[i].tasks[i2].text = value
    setTodolists(todo)
    if (!showSave) setShowSave(true)
  }

  const setTaskDone = (value: boolean, i: number, i2: number) => {
    var todo = [...todolists]
    todo[i].tasks[i2].done = value
    setTodolists(todo)
    if (!showSave) setShowSave(true)
  }

  const handleDeleteTask = (i: number, i2: number) => {
    var todo = [...todolists]
    todo[i].tasks.splice(i2, 1)
    setTodolists(todo)
    if (!showSave) setShowSave(true)
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Todo Lists", path: "/apps/todo/list", active: true },
        ]}
        title={"Todo Lists"}
      />
      <div
        className="page-title-box"
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Row style={{ width: "90%" }}>
          <Col xs={8}>
            <FormInput
              style={{ width: "30%" }}
              type="text"
              name="search"
              className="form-control"
              placeholder="Search..."
              key="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Row>
        <div
          className="page-title-right fs-18"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={() => {
            handleAdd()
          }}
        >
          <i className="uil-plus fs-24 m-2" />
          New Todo List
        </div>
      </div>

      {showSave && (
        <Button
          className="mb-3"
          onClick={() => {
            handleSave()
          }}
        >
          {saving ? (
            <div
              className="spinner"
              style={{ width: "25px", height: "25px" }}
            ></div>
          ) : (
            <div>Save</div>
          )}
        </Button>
      )}

      {loading ? (
        <div className="spinner"></div>
      ) : todolists.length === 0 ? (
        <div>You have no todo lists</div>
      ) : (
        <Row>
          {todolists
            .filter((one: any) =>
              one.title.toLowerCase().includes(search.toLowerCase())
            )
            .map((one: any, index: number) => {
              return (
                <Card
                  key={index}
                  style={{ width: "22%", padding: "10px", margin: "10px" }}
                >
                  <Row
                    style={{
                      alignContent: "center",
                    }}
                  >
                    <Col xs={10} className="fs-16 fw-medium">
                      <EditText
                        defaultValue={one.title}
                        formatDisplayText={(value) => {
                          if (value == "") return one.title
                          else return value
                        }}
                        onSave={(text) => {
                          if (text.value.length == 0)
                            handleChangeName("TodoList" + index, index)
                          else handleChangeName(text.value, index)
                        }}
                      />
                    </Col>
                    <Col xs={2} style={{ textAlign: "end" }}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(index)
                        }}
                      >
                        <i className="uil uil-multiply fs-14"></i>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div
                      className="fs-16"
                      style={{ cursor: "pointer", textAlign: "center" }}
                      onClick={() => {
                        handleAddTask(index)
                      }}
                    >
                      <>
                        <i className="uil-plus fs-16 m-2" />
                      </>
                    </div>
                  </Row>
                  {one.tasks.map((one: any, index2: number) => (
                    <Row className="mb-2" style={{ alignItems: "center" }}>
                      <Col xs={2}>
                        <Form.Check
                          type="checkbox"
                          checked={one.done}
                          onChange={(e) =>
                            setTaskDone(e.target.checked, index, index2)
                          }
                        />
                      </Col>
                      <Col
                        xs={8}
                        className="fs-14"
                        style={{ alignItems: "center" }}
                      >
                        <EditText
                          defaultValue={one.text}
                          style={
                            one.done ? { textDecoration: "line-through" } : {}
                          }
                          formatDisplayText={(value) => {
                            if (value == "") return one.text
                            else return value
                          }}
                          onSave={(text) =>
                            handleChangeTaskName(
                              text.value.length == 0
                                ? "task" + index2
                                : text.value,
                              index,
                              index2
                            )
                          }
                        />
                      </Col>
                      <Col xs={2}>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleDeleteTask(index, index2)
                          }}
                        >
                          <i className="uil uil-multiply fs-12"></i>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Card>
              )
            })}
        </Row>
      )}
      {/* <Row>
				<Table
					columns={columns}
					data={data}
					pageSize={5}
					sizePerPageList={sizePerPageList}
					isSortable={true}
					pagination={true}
					isSelectable={false}
					isSearchable={true}
					OffcanvasPlacement={OffcanvasPlacement}
					handleChange={handleChange}
				/>
			</Row> */}
    </>
  )
}

export default TodoList
