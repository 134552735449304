import config from "./config";
import * as io from "socket.io-client";

let socket: any;

const connect = () => {
	socket = io.connect(config.API_URL);
	sessionStorage.setItem("socket", socket.toString());
	console.log(sessionStorage.getItem("socket"));
};
export default { socket, connect };
