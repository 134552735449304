import { Card, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import Loader from "../../../components/Loader"

interface PersonalDetailsProps {
  userInfo: any | null
}

const PersonalDetails = ({ userInfo }: PersonalDetailsProps) => {
  console.log(userInfo)
  return !userInfo ? (
    <div style={{ height: 700, position: "relative" }}>
      <Loader />
    </div>
  ) : (
    <Card>
      <Card.Body>
        <div className="text-center mt-2">
          <img
            src={
              userInfo.image
                ? userInfo.image
                : "https://i.stack.imgur.com/l60Hf.png"
            }
            alt=""
            className="avatar-xxl rounded-circle"
          />
          <h4 className="mt-2 mb-0">{userInfo.name}</h4>
          <h6 className="text-muted fw-normal mt-2 mb-0">{userInfo.label}</h6>
          <h6 className="text-muted fw-normal mt-2 mb-0">
            {userInfo.company && userInfo.company.name}
          </h6>
          <Link
            to="#"
            onClick={() =>
              window.open(userInfo.company && userInfo.company.website)
            }
            style={{ color: "#6c757d" }}
          >
            <h6 className="text-muted fw-normal mt-2 mb-0 hyperlink">
              {userInfo.company && userInfo.company.website}
            </h6>
          </Link>
          {userInfo.links && userInfo.links.length > 0 && (
            <Row
              style={{
                display: "flex",
                width: "20%",
                alignContent: "center",
                justifyContent: "center",
                margin: "auto",
              }}
              className="mt-2"
            >
              {userInfo.links.map((one: any, index: any) => {
                return (
                  <Col>
                    <Link
                      key={index}
                      onClick={() => window.open(one.link)}
                      to="#"
                      style={{ color: "#6c757d" }}
                    >
                      <i
                        className={`uil uil-${one.name.toLowerCase()} fs-24`}
                      ></i>
                    </Link>
                  </Col>
                )
              })}
            </Row>
          )}
        </div>
        <div className="mt-3 pt-2 border-top">
          <h4 className="mb-2 fs-15" style={{ fontWeight: "bold" }}>
            Contact Information
          </h4>
          <div className="table-responsive">
            <table className="table table-borderless mb-0 text-muted">
              <tbody>
                <tr>
                  <th scope="row">Email</th>
                  <td>{userInfo.email}</td>
                </tr>
                <tr>
                  <th scope="row">Phone</th>
                  <td>{userInfo.phone}</td>
                </tr>
                {userInfo.label && (
                  <tr>
                    <th scope="row">Label</th>
                    <td>{userInfo.label}</td>
                  </tr>
                )}

                {userInfo.ID && (
                  <tr>
                    <th scope="row">National ID / Passport ID</th>
                    <td>{userInfo.ID}</td>
                  </tr>
                )}
                {userInfo.address && (
                  <tr>
                    <th scope="row">Address</th>
                    <td>{userInfo.address}</td>
                  </tr>
                )}
                {userInfo.campaign && (
                  <tr>
                    <th scope="row">Campaign</th>
                    <td>{userInfo.campaign.title}</td>
                  </tr>
                )}
                {userInfo.campaign && (
                  <tr>
                    <th scope="row">Channel</th>
                    <td>{userInfo.campaign.channel.name}</td>
                  </tr>
                )}
                {userInfo.project && (
                  <tr>
                    <th scope="row">project</th>
                    <td>{userInfo.project}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default PersonalDetails
