import { useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { Row, Col, Button, Offcanvas, Alert } from "react-bootstrap"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormInput } from "../../../components"

import { APICore } from "../../../helpers/api/apiCore"

const api = new APICore()

const AddEditSurvey = ({
    isEditable,
    survey,
    handleChange,
}: {
    isEditable: boolean
    survey?: any
    handleChange: () => void
}) => {
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>()
    const errorRef = useRef<null | HTMLDivElement>(null)
    const userMe = JSON.parse(sessionStorage.getItem("user")!)

    /**
     * handle modal toggle
     */
    const toggle = () => {
        setShow((prevState) => !prevState)
    }

    const schemaResolver = yupResolver(
        yup.object().shape({
            title: yup.string().required(),
            description: yup.string().required(),
            url: yup.string().required(),
        })
    )
    const schemaResolver1 = yupResolver(
        yup.object().shape({
            title: yup.string(),
            description: yup.string(),
            url: yup.string(),
        })
    )

    const methods = useForm({
        defaultValues: survey,
        resolver: isEditable ? schemaResolver1 : schemaResolver,
    })
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = methods

    const onSubmitEvent = (data: any) => {
        setError(null)
        if (isEditable) {
            let body = {
                id: survey?.id,
                ...(survey?.title !== data.title && {
                    title: data.title,
                }),
                ...(survey?.description !== data.description && {
                    description: data.description,
                }),
                ...(survey?.url !== data.url && {
                    url: data.url,
                }),
            }
            setLoading(true)
            api.updatePatch(`/survey/updateSurvey/${survey._id}`, body)
                .then((response) => {
                    setLoading(false)
                    toggle()
                    handleChange()
                })
                .catch((e) => {
                    setError(e)
                    setLoading(false)
                    errorRef.current?.scrollIntoView({ behavior: "smooth" })
                })
        } else {
            let body = {
                title: data.title,
                description: data.description,
                url: data.url,
            }
            setLoading(true)
            api.create("survey/addSurvey", body)
                .then((response) => {
                    setLoading(false)
                    toggle()
                    handleChange()
                })
                .catch((e) => {
                    setError(e)
                    setLoading(false)
                    errorRef.current?.scrollIntoView({ behavior: "smooth" })
                })
        }
    }

    return (
        <>
            {isEditable ? (
                <div
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => {
                        userMe.access.survey.update && toggle()
                    }}
                >
                    <i className="uil-edit fs-14" />
                </div>
            ) : (
                <div
                    className="fs-18"
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => {
                        toggle()
                    }}
                >  {JSON.parse(sessionStorage.getItem("user")!).access.survey.add===true?
<>
                    <i className="uil-plus fs-24 m-2" />
                    New Survey</>
                    :
                    ""
                    }
                </div>
            )}
            <Offcanvas show={show} onHide={toggle} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <h5 id="offcanvasTopLabel">Survey</h5>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {error && (
                        <div ref={errorRef}>
                            <Alert variant="danger" className="my-2">
                                {error}
                            </Alert>
                        </div>
                    )}
                    <form
                        noValidate
                        name="chat-form"
                        id="chat-form"
                        onSubmit={handleSubmit(onSubmitEvent)}
                    >
                        <Row>
                            <Col sm={12}>
                                <FormInput
                                    type="text"
                                    label="Title"
                                    name="title"
                                    className="form-control"
                                    placeholder="Insert Title"
                                    containerClass={"mb-3"}
                                    register={register}
                                    key="title"
                                    errors={errors}
                                    control={control}
                                />
                            </Col>
                            <Col sm={12}>
                                <FormInput
                                    type="textarea"
                                    label="Description"
                                    name="description"
                                    className="form-control"
                                    placeholder="Insert Description"
                                    containerClass={"mb-3"}
                                    register={register}
                                    key="description"
                                    errors={errors}
                                    control={control}
                                />
                            </Col>
                            <Col sm={12}>
                                <FormInput
                                    type="text"
                                    label="URL"
                                    name="url"
                                    className="form-control"
                                    placeholder="Insert URL"
                                    containerClass={"mb-3"}
                                    register={register}
                                    key="url"
                                    errors={errors}
                                    control={control}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={16} className="text-center">
                                {loading ? (
                                    <div className="spinner"></div>
                                ) : (
                                    <Button
                                        variant="success"
                                        type="submit"
                                        className="btn btn-success"
                                        style={{ width: "100px" }}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default AddEditSurvey
