import { useState, useEffect } from "react"
import { Row, Col, Card, Form,Alert } from "react-bootstrap"
import { APICore } from "../../../helpers/api/apiCore"
import { FormInput } from "../../../components"
import PageTitle from "../../../components/PageTitle"
import { format } from "date-fns"

const DaysOffRequests = () => {
  const baseUrl = "/user"
  const [daysOffRequests, setDaysOffRequests] = useState<any[]>([])
  const [daysOffYears, setDaysOffYears] = useState<any[]>([])
  const [selectedYear, setSelectedYear] = useState<string>(''); // State to store the selected year

  const [loading, setLoading] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [acceptingRejecting, setAcceptingRejecting] = useState<any[]>([])
  const [search, setSearch] = useState<String>("")
  const [status, setStatus] = useState<String[]>([
    "pending",
    // "accepted",
    // "rejected",
  ])
  const handleYearChange = (event:any) => {
    setSelectedYear(event.target.value); // Update the selected year when it changes
};

  useEffect(() => {
    setLoading(true)
    const api = new APICore()
    api
      .get(`${baseUrl}/getAllDaysOffRequests?year=${selectedYear}`, null)
      .then((res) => {
        // console.log(res.data)
        setDaysOffRequests(res.data.daysOff)
        // setDaysOffYears([2023, 2024])
        setDaysOffYears(res?.data?.years)

        setLoading(false)
        setReload(false)
      })
      .catch((err) => {
        setLoading(false)
        setReload(false)
      })
  }, [reload,selectedYear])
  const [error, setError] = useState<any>()

  const handleAccept = (id: String) => {
    setAcceptingRejecting((prev) => [...prev, id])
    const api = new APICore()
    api
      .updatePatch(`${baseUrl}/acceptDaysOffRequest/${id}`, {})
      .then((response) => {
        setReload(true)
        setAcceptingRejecting((prev) => prev.filter((one) => one === id))
      })
      .catch((e) => {
        console.log(e)
        setReload(true)
        setAcceptingRejecting([null])
        // setLoading(false)

setError(e)
      })
  }

  const handleReject = (id: String) => {
    setAcceptingRejecting((prev) => [...prev, id])
    const api = new APICore()
    api
      .updatePatch(`${baseUrl}/rejectDaysOffRequest/${id}`, {})
      .then((response) => {
        setReload(true)
        setAcceptingRejecting((prev) => prev.filter((one) => one === id))
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: "Days Off Requests",
            path: "/apps/daysoffrequests/list",
            active: true,
          },
        ]}
        title={"Days Off Requests"}
      />
         {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}
      <Row style={{ width: "90%" }} className="mt-2">
        <Col xs={4}>
          <FormInput
            style={{ width: "50%" }}
            type="text"
            name="search"
            className="form-control"
            placeholder="Search..."
            key="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col xs={2}>
        <FormInput
                      name="label"
                      
                      placeholder="Enter Label"
                      type="select"
                      containerClass="mb-3"
                      key="label"
                      onChange={handleYearChange}
                      rows={"12"}
                    >
                      {daysOffYears.map((year, index) => (
     <option key={index} value={year}>{year}</option> 
    ))}
                   
                    </FormInput>
        </Col>
        <Col xs={6} style={{ alignItems: "start" }}>
          <Row>
            <Col>
              <Row>
                <Col xs={2} style={{ alignItems: "center" }}>
                  <Form.Check
                    type="checkbox"
                    checked={status.includes("pending")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setStatus((prev) => [...prev, "pending"])
                      } else {
                        setStatus((prev) =>
                          prev.filter((one) => one !== "pending")
                        )
                      }
                    }}
                  />
                </Col>
                <Col
                  xs={10}
                  className="fs-14"
                  style={{
                    alignItems: "end",
                  }}
                >
                  Pending
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs={2}>
                  <Form.Check
                    type="checkbox"
                    checked={status.includes("accepted")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setStatus((prev) => [...prev, "accepted"])
                      } else {
                        setStatus((prev) =>
                          prev.filter((one) => one !== "accepted")
                        )
                      }
                    }}
                  />
                </Col>
                <Col
                  xs={10}
                  className="fs-14"
                  style={{
                    alignItems: "center",
                  }}
                >
                  Accepted
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs={2}>
                  <Form.Check
                    type="checkbox"
                    checked={status.includes("rejected")}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setStatus((prev) => [...prev, "rejected"])
                      } else {
                        setStatus((prev) =>
                          prev.filter((one) => one !== "rejected")
                        )
                      }
                    }}
                  />
                </Col>
                <Col
                  xs={10}
                  className="fs-14"
                  style={{
                    alignItems: "center",
                  }}
                >
                  Rejected
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {loading ? (
        <div className="spinner"></div>
      ) : daysOffRequests.length === 0 ? (
        <div>There are no requests</div>
      ) : (
        <Row>
          {daysOffRequests
            .filter(
              (one: any) =>
                one.user.name.toLowerCase().includes(search.toLowerCase()) &&
                status.includes(one.status)
            )
            .map((one: any, index: number) => {
              console.log(one)
              return (
                <Card
                  style={{
                    width: "300px",
                    backgroundColor: `${
                      one.status === "pending"
                        ? "#FFBE0B"
                        : one.status === "accepted"
                        ? "#43D39E"
                        : "#FF5C75"
                    }`,
                  }}
                  className="m-2"
                >
                  <Card.Body>
                    <Row>
                      <h3
                        style={{
                          color: "white",
                          textAlign: "center",
                        }}
                        className="fw-bold mb-2"
                      >
                        {one.status.toUpperCase()}
                      </h3>
                      <p  style={{
                          color: "white",
                          textAlign: "center",
                          fontSize:'17px'
                        }}>{one.days} days</p>
                    </Row>
                    <Row>
                      <h3 style={{ color: "white", textAlign: "center" }}>
                        from {one.user.name}
                      </h3>
                    </Row>
                    <Row>
                      <h4 style={{ color: "white", textAlign: "center" }}>
                        {`${format(new Date(one.start), "EEE do")} of ${format(
                          new Date(one.start),
                          "MMMM yyyy"
                        )}`}
                      </h4>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                      <i
                        className="uil uil-arrow-down fs-24 mb-2"
                        style={{ color: "white" }}
                      />
                    </Row>
                    <Row>
                      <h4 style={{ color: "white", textAlign: "center" }}>
                        {`${format(new Date(one.end), "EEE do")} of ${format(
                          new Date(one.end),
                          "MMMM yyyy"
                        )}`}
                      </h4>
                    </Row>

                    {one.status === "pending" &&
                      (acceptingRejecting.includes(one._id) ? (
                        <div className="spinner"></div>
                      ) : (
                        JSON.parse(sessionStorage.getItem("user")!).access
                          .daysOff.update && (
                          <Row
                            className=" mt-2"
                            style={{ textAlign: "center" }}
                          >
                            <Col>
                              <i
                                className="uil uil-check-circle fs-24"
                                style={{ color: "#43D39E", cursor: "pointer" }}
                                onClick={() => {
                                  handleAccept(one._id)
                                }}
                              />
                            </Col>
                            <Col>
                              <i
                                className="uil uil-times-circle fs-24"
                                style={{ color: "#FF5C75", cursor: "pointer" }}
                                onClick={() => {
                                  handleReject(one._id)
                                }}
                              />
                            </Col>
                          </Row>
                        )
                      ))}
                  </Card.Body>
                </Card>
              )
            })}
        </Row>
      )}
    </>
  )
}

export default DaysOffRequests
