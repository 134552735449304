import { useState, useRef, ChangeEvent, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Row, Col, Button, Offcanvas, Alert, Form } from "react-bootstrap"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
// components
import { FormInput } from "../../../components"

import { APICore } from "../../../helpers/api/apiCore"
import axios from "axios"
import { CampaignInfoTypes } from "./data2"

import { UserInfoTypes } from "./data"
const api = new APICore()
const baseUrl = "/user"

const AddEditCampaign = ({
  isEditable,
  campaign,
  handleChange,
}: {
  isEditable: boolean
  campaign?: CampaignInfoTypes
  handleChange: (type: string, values: any) => void
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [file, setFile] = useState<any>()
  const [preview, setPreview] = useState<any>()
  const hiddenFileInput = useRef<null | HTMLInputElement>(null)
  const [error, setError] = useState<string | null>()
  const errorRef = useRef<null | HTMLDivElement>(null)

  const [page, setAccess] = useState<any>(
    isEditable
      ? campaign?.page
      : {
          name: {
            in: true,
            required: true,
          },
          email: {
            in: true,
            required: true,
          },
          phone: {
            in: true,
            required: true,
          },
          note: {
            in: false,
            required: false,
          },
          project: {
            in: false,
            required: false,
          },
          projectName: {
            in: false,
            required: false,
          },
          feedback: {
            in: false,
            required: false,
          },
        }
  )

  const userMe = JSON.parse(sessionStorage.getItem("user")!)

  /**
   * handle modal toggle
   */
  const toggle = () => {
    setShow((prevState) => !prevState)
  }

  console.log("Here Now")

  const schemaResolver = yupResolver(
    yup.object().shape({
      // name: yup.string().required("Please enter the user name"),
      title: yup.string().required("Please enter the title"),

      budget: yup.string().required("Please enter the gender"),
      // about: yup
      //     .string()
      //     .required("Please enter a brief description about the user"),
      // facebook: yup.string(),
      // instagram: yup.string(),
      // whatsapp: yup.string(),
      // linkedin: yup.string(),
      // github: yup.string(),
    })
  )

  const methods = useForm({
    defaultValues: campaign,
    resolver: schemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods

  const onSubmitEvent = async (data: any) => {
    // e.preventDefault()
    // console.log(e.target)

    setError(null)
    // if (isEditable) {
    // let links: { name: string; link: string }[] = []
    // links = [
    //     ...links,
    //     ...(data.facebook && [
    //         { name: "facebook", link: data.facebook },
    //     ]),
    //     ...(data.instagram && [
    //         { name: "instagram", link: data.instagram },
    //     ]),
    //     ...(data.whatsapp && [
    //         { name: "whatsapp", link: data.whatsapp },
    //     ]),
    //     ...(data.linkedin && [
    //         { name: "linkedin", link: data.linkedin },
    //     ]),
    //     ...(data.github && [{ name: "github", link: data.github }]),
    // ]
    // let body = {
    //     id: user?.id,
    //     ...(user?.name !== data.name && {
    //         name: data.name,
    //     }),
    //     ...(user?.about !== data.about && {
    //         about: data.about,
    //     }),
    //     ...(user?.title !== data.title && {
    //         title: data.title,
    //     }),
    //     ...(user?.gender !== data.gender && {
    //         gender: data.gender,
    //     }),
    //     // ...(user?.page !== page && {
    //     //     page: page,
    //     // }),
    //     ...(linksChanged(links) && { links: links }),
    // }
    // setLoading(true)
    // if (!file && Object.keys(body).length === 1) {
    //     setLoading(false)
    //     toggle()
    // } else {
    //     if (!file) {
    //         api.updatePatch("/user/updateUser", body)
    //             .then((response) => {
    //                 setLoading(false)
    //                 toggle()
    //                 handleChange("Edit", {})
    //             })
    //             .catch((e) => {
    //                 setError(e)
    //                 setLoading(false)
    //                 errorRef.current?.scrollIntoView({
    //                     behavior: "smooth",
    //                 })
    //             })
    //     } else {
    //         let bodyFormData = new FormData()
    //         bodyFormData.append("file", file, file?.name)
    //         axios
    //             .post(`/data/upload`, bodyFormData)
    //             .then((response) => {
    //                 body = {
    //                     ...body,
    //                     ...{
    //                         image: response.data.url,
    //                     },
    //                 }
    //                 api.updatePatch("/user/updateUser", body)
    //                     .then((response) => {
    //                         setLoading(false)
    //                         toggle()
    //                         handleChange("Edit", {})
    //                     })
    //                     .catch((e) => {
    //                         setError(e)
    //                         setLoading(false)
    //                         errorRef.current?.scrollIntoView({
    //                             behavior: "smooth",
    //                         })
    //                     })
    //             })
    //             .catch((e) => {
    //                 setError(e)
    //                 setLoading(false)
    //                 errorRef.current?.scrollIntoView({
    //                     behavior: "smooth",
    //                 })
    //             })
    //     }
    // }
    // }
    //  else {
    // if (!file) {
    //     setError("Please choose an image for the user.")
    //     return
    // }
    // let links: { name: string; link: string }[] = []
    // links = [
    //     ...(data.facebook && [
    //         { name: "facebook", link: data.facebook },
    //     ]),
    //     ...(data.instagram && [
    //         { name: "instagram", link: data.instagram },
    //     ]),
    //     ...(data.whatsapp && [
    //         { name: "whatsapp", link: data.whatsapp },
    //     ]),
    //     ...(data.linkedin && [
    //         { name: "linkedin", link: data.linkedin },
    //     ]),
    //     ...(data.github && [{ name: "github", link: data.github }]),
    // ]
    // console.log(links)
    let backgroundImageUrl = ""

    if (!selectedChannel) {
      setError("Please select a channel")
      return
    }

    if (backgroundImage !== null) {
      console.log("Here")
      const bodyFormData = new FormData()
      bodyFormData.append("file", backgroundImage, backgroundImage.name)

      try {
        const response = await axios.post(`/data/upload`, bodyFormData)
        backgroundImageUrl = response.data.url
      } catch (error) {
        console.error("Error uploading image:", error)
        // Handle the error, e.g., display an error message
      }
    }
    let body = {
      budget: data.budget,
      title: data.title,
      channel: selectedChannel,
      page: page,
      backgroundImage: backgroundImageUrl,
    }
    // let bodyFormData = new FormData()
    // bodyFormData.append("file", file, file?.name)
    // setLoading(true)
    // axios
    //     .post(`/data/upload`, bodyFormData)
    //     .then((response) => {
    // body = {
    // ...body,
    // ...{
    //     image: response.data.url,
    // },
    // }
    console.log(body)
    axios
      .post(`/campaign/addCampaign`, body, {
        headers: {
          Authorization: JSON.parse(sessionStorage.getItem("user")!).token,
        },
      })
      .then((response) => {
        console.log(response)
        setLoading(false)
        toggle()
        handleChange("Add", response.data)
      })
      .catch((e) => {
        console.log(e)

        setError(e)
        setLoading(false)
        errorRef.current?.scrollIntoView({
          behavior: "smooth",
        })
      })
    // })
    // .catch((e) => {
    //     console.log(e)
    //     setLoading(false)
    //     errorRef.current?.scrollIntoView({ behavior: "smooth" })
    // })
    // }
    console.log(page)
    // isEditable ? onUpdateEvent(body) : onAddEvent(body);
  }

  const removeFile = () => {
    if (file) {
      setFile(null)
      hiddenFileInput.current!.value = ""
    }
  }

  const handleUpload = () => {
    hiddenFileInput.current?.click()
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    setFile(e.target.files[0])
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setPreview(objectUrl)

    // 🚩 do the file upload here normally...
  }
  const [chanelOptions, setChanelOptions] = useState([])

  const [selectedChannel, setSelectedChannel] = useState(null)
  const [backgroundImage, setBackgroundImage] = useState<File | null>(null) // Declare file as File | null
  const handleImageChange = (event: any) => {
    // Handle the selected image here
    const file = event.target.files[0]
    setBackgroundImage(file)
  }
  useEffect(() => {
    // Make a GET request to fetch data from the backend
    console.log("response")
    axios.get("/channel").then((response) => {
      console.log(response)
      const data = response.data // Get the data from the response

      // Map over the data to create option elements
      const options = data.map((chanel: any) => (
        <option key={chanel._id} value={chanel._id}>
          {chanel.name}
        </option>
      ))

      // Add a default option at the beginning
      options.unshift(
        <option key="no-value" value="" disabled>
          Select Chanel
        </option>
      )

      // Set the options in state
      setChanelOptions(options)
    })
  }, [])
  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value
    setSelectedChannel(selectedValue)
  }

  return (
    <>
      {isEditable ? (
        <div
          className="fs-18"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={() => {
            userMe.access.users.update && toggle()
          }}
        >
          <i className="uil-edit fs-24 m-2" />
          Edit Campaign
        </div>
      ) : (
        <div
          className="fs-18"
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={() => {
            userMe.access.users.add && toggle()
          }}
        >
          <i className="uil-plus fs-24 m-2" />
          New Campaign
        </div>
      )}
      <Offcanvas
        show={show}
        onHide={toggle}
        placement={"end"}
        style={{ width: "600px" }}
      >
        <Offcanvas.Header closeButton>
          <h5 id="offcanvasTopLabel">Campaign</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {error && (
            <div ref={errorRef}>
              <Alert variant="danger" className="my-2">
                {error}
              </Alert>
            </div>
          )}

          <Row style={{ marginBottom: "5px" }}>
            <Col style={{ textAlign: "center" }}>
              <div className="col-sm-auto">
                <div className="btn-group">
                  {/* <div onClick={handleUpload}>
                                        {isEditable ? (
                                            <img
                                                src={
                                                    file ? preview : campaign?.image
                                                }
                                                className="rounded-circle align-content-center"
                                                style={{ cursor: "pointer" }}
                                                height="100"
                                                width="100"
                                                alt=""
                                            />
                                        ) : (
                                            <div style={{ cursor: "pointer" }}>
                                                {file ? (
                                                    <img
                                                        src={
                                                            file
                                                                ? preview
                                                                : campaign?.image
                                                        }
                                                        className="rounded-circle align-content-center"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        height="100"
                                                        width="100"
                                                        alt=""
                                                    />
                                                ) : (
                                                    <i
                                                        className="uil uil-plus-circle"
                                                        style={{
                                                            fontSize: "50px",
                                                        }}
                                                    ></i>
                                                )}
                                            </div>
                                        )}
                                    </div> */}
                  <input
                    accept="image/*"
                    id="upload"
                    ref={hiddenFileInput}
                    type="file"
                    className="bi bi-paperclip fs-18"
                    name="myFile"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "50px" }}>
            <Col style={{ textAlign: "center" }}>
              {file && (
                <div style={{ cursor: "pointer" }} onClick={removeFile}>
                  <i className="uil uil-trash" style={{ fontSize: "25px" }}></i>
                </div>
              )}
            </Col>
          </Row>

          <form
            // noValidate
            name="chat-form"
            id="chat-form"
            onSubmit={handleSubmit(onSubmitEvent)}
          >
            <Row>
              {!isEditable && (
                <>
                  <Col sm={6}>
                    <FormInput
                      type="text"
                      label="Budget"
                      name="budget"
                      className="form-control"
                      placeholder="Insert Budget"
                      containerClass={"mb-3"}
                      register={register}
                      key="budget"
                      errors={errors}
                      control={control}
                    />
                  </Col>
                </>
              )}
              <Col sm={6}>
                <FormInput
                  type="text"
                  label="Title"
                  name="title"
                  className="form-control"
                  placeholder="Insert Title"
                  containerClass={"mb-3"}
                  register={register}
                  key="title"
                  errors={errors}
                  control={control}
                />
              </Col>
              <Col sm={6}>
                <FormInput
                  name="Chanel"
                  label="Chanel"
                  type="select"
                  className="form-control"
                  placeholder="Choose Chanel"
                  containerClass={"mb-3"}
                  onChange={handleSelectChange}
                  value={selectedChannel || ""}
                  defaultValue="no-value"
                >
                  {chanelOptions}
                </FormInput>
              </Col>
              <Col sm={6}>
                <input
                  type="file"
                  accept="image/*" // Allow only image files
                  onChange={handleImageChange}
                />
              </Col>
              <Row className="mb-2">
                <Col xs={2}></Col>
                <Col xs={2}></Col>
                <Col xs={2}>select</Col>
                <Col xs={2}>required</Col>
              </Row>
              <Row>
                <Col xs={4}>Name</Col>

                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.name.in}
                    // onChange={(e) => {
                    //   setAccess({
                    //     ...page,
                    //     name: {
                    //       ...page.name,
                    //       in: !page.name.in,
                    //     },
                    //   })
                    // }}
                    disabled={true}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.name.required}
                    // onChange={(e) => {
                    //   setAccess({
                    //     ...page,
                    //     name: {
                    //       ...page.name,
                    //       required: !page.name.required,
                    //     },
                    //   })
                    // }}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Email</Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.email.in}
                    // onChange={(e) => {
                    //   setAccess({
                    //     ...page,
                    //     email: {
                    //       ...page.email,
                    //       in: !page.email.in,
                    //     },
                    //   })
                    // }}
                    disabled={true}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.email.required}
                    // onChange={(e) => {
                    //   setAccess({
                    //     ...page,
                    //     email: {
                    //       ...page.email,
                    //       required: !page.email.required,
                    //     },
                    //   })
                    // }}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Phone</Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.phone.in}
                    // onChange={(e) => {
                    //   setAccess({
                    //     ...page,
                    //     phone: {
                    //       ...page.phone,
                    //       in: !page.phone.in,
                    //     },
                    //   })
                    // }}
                    disabled={true}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.phone.required}
                    // onChange={(e) => {
                    //   setAccess({
                    //     ...page,
                    //     phone: {
                    //       ...page.phone,
                    //       required: !page.phone.required,
                    //     },
                    //   })
                    // }}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Note</Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.note.in}
                    onChange={(e) => {
                      if (page.note.in)
                        setAccess({
                          ...page,
                          note: {
                            in: !page.note.in,
                            required: false,
                          },
                        })
                      else
                        setAccess({
                          ...page,
                          note: {
                            ...page.note,
                            in: !page.note.in,
                          },
                        })
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.note.required}
                    onChange={(e) => {
                      if (page.note.required)
                        setAccess({
                          ...page,
                          note: {
                            ...page.note,
                            required: !page.note.required,
                          },
                        })
                      else
                        setAccess({
                          ...page,
                          note: {
                            in: true,
                            required: !page.note.required,
                          },
                        })
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Project</Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.project.in}
                    onChange={(e) => {
                      if (page.project.in)
                        setAccess({
                          ...page,
                          project: {
                            in: !page.project.in,
                            required: false,
                          },
                        })
                      else
                        setAccess({
                          ...page,
                          project: {
                            ...page.project,
                            in: !page.project.in,
                          },
                        })
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.project.required}
                    onChange={(e) => {
                      if (page.project.required)
                        setAccess({
                          ...page,
                          project: {
                            ...page.project,
                            required: !page.project.required,
                          },
                        })
                      else
                        setAccess({
                          ...page,
                          project: {
                            in: true,
                            required: !page.project.required,
                          },
                        })
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Project Name</Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.projectName.in}
                    onChange={(e) => {
                      if (page.projectName.in)
                        setAccess({
                          ...page,
                          projectName: {
                            in: !page.projectName.in,
                            required: false,
                          },
                        })
                      else
                        setAccess({
                          ...page,
                          projectName: {
                            ...page.projectName,
                            in: !page.projectName.in,
                          },
                        })
                    }}
                  />
                </Col>

                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.projectName.required}
                    onChange={(e) => {
                      if (page.projectName.required)
                        setAccess({
                          ...page,
                          projectName: {
                            ...page.projectName,
                            required: !page.projectName.required,
                          },
                        })
                      else
                        setAccess({
                          ...page,
                          projectName: {
                            in: true,
                            required: !page.projectName.required,
                          },
                        })
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Feedback</Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.feedback.in}
                    onChange={(e) => {
                      if (page.feedback.in)
                        setAccess({
                          ...page,
                          feedback: {
                            in: !page.feedback.in,
                            required: false,
                          },
                        })
                      else
                        setAccess({
                          ...page,
                          feedback: {
                            ...page.feedback,
                            in: !page.feedback.in,
                          },
                        })
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    className="mb-2"
                    checked={page.feedback.required}
                    onChange={(e) => {
                      if (page.feedback.required)
                        setAccess({
                          ...page,
                          feedback: {
                            ...page.feedback,
                            required: !page.feedback.required,
                          },
                        })
                      else
                        setAccess({
                          ...page,
                          feedback: {
                            in: true,
                            required: !page.feedback.required,
                          },
                        })
                    }}
                  />
                </Col>
              </Row>
            </Row>

            <Row>
              <Col xs={16} className="text-center">
                {loading ? (
                  <div className="spinner"></div>
                ) : (
                  <Button
                    variant="success"
                    type="submit"
                    className="btn btn-success"
                    style={{ width: "100px" }}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default AddEditCampaign
