import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Col, Row } from "react-bootstrap";

import PageTitle from "../../../components/PageTitle";
import { APICore } from "../../../helpers/api/apiCore";

import PersonalDetails from "./PersonalDetails";
const api = new APICore();

const ContactDetails = () => {
	const { id } = useParams<{ id: string }>();
	const [userInfo, setUserInfo] = useState<any | null>(null);
	const [reload, setReload] = useState<boolean>(false);

	useEffect(() => {
		setUserInfo(null);
		api
			.get(`/contact/getContact/${id}`, null)
			.then((response) => {
				const responseData = response.data.response;
				setUserInfo(responseData);
				setReload(false);
			})
			.catch((e) => {
				console.log(e);
				setReload(false);
			});
	}, [reload, id]);

	return (
		<>
			<PageTitle
				breadCrumbItems={[
					{ label: "Contacts", path: "/apps/contacts" },
					{
						label: "Contact Details",
						path: "/apps/contact/:id",
						active: true,
					},
				]}
				title={"Contact Details"}
			/>

			<Row>
				<Col>
					<PersonalDetails userInfo={userInfo} />
				</Col>
			</Row>
		</>
	);
};

export default ContactDetails;
