import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { Row, Col, Card } from "react-bootstrap"
import { APICore } from "../../../helpers/api/apiCore"
import { FormInput } from "../../../components"
import PageTitle from "../../../components/PageTitle"
import AddEditSurvey from "./AddEditSurvey"

const SurveyList = () => {
  const baseUrl = "/survey"
  const [search, setSearch] = useState<String>("")
  const [surveys, setSurveys] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const userMe = JSON.parse(sessionStorage.getItem("user")!)

  useEffect(() => {
    setLoading(true)
    const api = new APICore()
    api
      .get(`${baseUrl}/`, null)
      .then((res) => {
        setSurveys(res.data.response)
        console.log(res.data.response)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }, [reload])

  const handleDelete = (id: string) => {
    if (window.confirm("Are you sure you wish to delete this survey?")) {
      const api = new APICore()
      api
        .delete(`survey/deleteSurvey/${id}`)
        .then((response) => {
          setReload((prev) => !prev)
        })
        .catch((e) => {})
    }
  }

  const isValidUrl = (urlString: string) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ) // validate fragment locator
    return !!urlPattern.test(urlString)
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: "Surveys",
            path: "/apps/survey/list",
            active: true,
          },
        ]}
        title={"Surveys"}
      />
      <div
        className="page-title-box"
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Row style={{ width: "90%" }}>
          <Col xs={8}>
            <FormInput
              style={{ width: "30%" }}
              type="text"
              name="search"
              className="form-control"
              placeholder="Search..."
              key="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Row>
        <div
          className="page-title-right fs-18"
          style={{ cursor: "pointer", fontWeight: "bold" }}
        >
          <AddEditSurvey
            isEditable={false}
            handleChange={() => {
              setReload((prev) => !prev)
            }}
          />
        </div>
      </div>

      {/* {showSave && (
				<Button
					className="mb-3"
					onClick={() => {
						handleSave();
					}}
				>
					{saving ? (
						<div
							className="spinner"
							style={{ width: "25px", height: "25px" }}
						></div>
					) : (
						<div>Save</div>
					)}
				</Button>
			)} */}

      {loading ? (
        <div className="spinner"></div>
      ) : surveys.length === 0 ? (
        <div>There are no surveys</div>
      ) : (
        <Row>
          {surveys
            .filter(
              (one: any) =>
                one.title.toLowerCase().includes(search.toLowerCase()) ||
                one.description.toLowerCase().includes(search.toLowerCase())
            )
            .map((one: any, index: number) => {
              return (
                <Card
                  key={index}
                  style={{
                    width: "22%",
                    padding: "10px",
                    margin: "10px",
                  }}
                >
                  <Row
                    style={{
                      alignContent: "center",
                    }}
                  >
                    <Col xs={6} className="fs-16 fw-medium">
                      {one.title}
                    </Col>
                    <Col xs={2} style={{ textAlign: "end" }}>
                      <Link
                        key={index}
                        onClick={() => {
                          console.log(one.url)
                          if (isValidUrl(one.url)) window.open(one.url)
                          else window.open("/error-404")
                        }}
                        to="#"
                      >
                        <i className="uil uil-link fs-14"></i>
                      </Link>
                    </Col>
                    <Col xs={2} style={{ textAlign: "end" }}>
                      <AddEditSurvey
                        isEditable={true}
                        survey={one}
                        handleChange={() => {
                          setReload((prev) => !prev)
                        }}
                      />
                    </Col>
                    <Col xs={2} style={{ textAlign: "end" }}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          userMe.access.survey.delete && handleDelete(one._id)
                        }}
                      >
                        <i className="uil uil-multiply fs-14"></i>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="fs-14 fw-normal mt-2">
                      {one.description}
                    </div>
                  </Row>
                </Card>
              )
            })}
        </Row>
      )}
    </>
  )
}

export default SurveyList
