import { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { APICore } from "../../../helpers/api/apiCore";
import { FormInput } from "../../../components";
import PageTitle from "../../../components/PageTitle";
import { format } from "date-fns";
import { Typeahead } from "react-bootstrap-typeahead";
import ShreyuDatepicker from "../../../components/Datepicker";
import { Pagination } from "@mui/material";

const LogInOut = () => {
	const baseUrl = "/loginout";
	const [logInOut, setLogInOut] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [reload, setReload] = useState<boolean>(false);
	const [userslist, setUserslist] = useState<any[]>([]);
	const [start, setStart] = useState<Date>();
	const [end, setEnd] = useState<Date>();
	const [user, setUser] = useState<any | null>(null);
	const [page, setPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);

	useEffect(() => {
		setLoading(true);
		const api = new APICore();
		if (userslist.length === 0) {
			api
				.get("/user/", null)
				.then((res) => {
					let u = res.data.response.map((one: any, index: number) => {
						return { id: index, value: one._id, label: one.name };
					});
					setUserslist(u);
				})
				.catch((err) => {});
		}
		api
			.create(`${baseUrl}/${page}`, {
				filter: {
					...(user && { "user.id": user[0].value }),
					...(start && { login: { $gte: start, $lte: end } }),
				},
			})
			.then((res) => {
				setLogInOut(res.data.response);
				setTotalPages(res.data.count);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, [reload]);

	return (
		<>
			<PageTitle
				breadCrumbItems={[
					{
						label: "Log In/Out Records",
						path: "/apps/loginout/list",
						active: true,
					},
				]}
				title={"Log In/Out Records"}
			/>
			<Row style={{ width: "90%", alignItems: "center" }} className="mt-2 mb-2">
				<Col xs={4}>
					{userslist.length > 0 && (
						<div>
							<div className="fw-medium">User</div>
							<Typeahead
								id="select2"
								labelKey={"label"}
								multiple={false}
								onChange={(value) => {
									setUser(value);
								}}
								options={userslist}
								placeholder="Choose a user..."
							/>
						</div>
					)}
				</Col>
				<Col xs={4}>
					{userslist.length > 0 && (
						<div>
							<div className="fw-medium">Date</div>
							<Row>
								<Col xs={10}>
									<ShreyuDatepicker
										hideAddon
										dateFormat="dd-MM-yyyy"
										value={start}
										onChange={(date) => {
											setStart(date);
											let endDate = new Date(date);
											console.log(endDate);
											endDate.setDate(endDate.getDate() + 1);
											setEnd(endDate);
										}}
									/>
								</Col>
								<Col xs={2}>
									{start && (
										<div
											className="fs-14"
											style={{ cursor: "pointer", fontWeight: "bold" }}
											onClick={() => {
												setStart(undefined);
												setEnd(undefined);
											}}
										>
											<i className="uil-multiply fs-18 m-2" />
										</div>
									)}
								</Col>
							</Row>
						</div>
					)}
				</Col>
				<Col xs={2}>
					<div
						className="fs-14"
						style={{ cursor: "pointer", fontWeight: "bold" }}
						onClick={() => {
							setReload(!reload);
						}}
					>
						<i className="uil-filter fs-18 m-2" />
						Apply Filter
					</div>
				</Col>
				<Col xs={2}></Col>
			</Row>
			{loading ? (
				<div className="spinner"></div>
			) : logInOut.length === 0 ? (
				<div className="mt-2">There are no log in/out records</div>
			) : (
				<Col style={{ height: "100%" }}>
					<Row>
						{logInOut.map((one: any, index: number) => {
							return (
								<Card
									style={{
										width: "300px",
									}}
									className="m-2"
								>
									<Card.Body>
										<Row className="mb-2">
											<h4 style={{ textAlign: "center" }}>{one.user.name}</h4>
										</Row>
										<Row>
											<h5 style={{ textAlign: "center" }}>
												{`${format(
													new Date(one.login),
													"EEE dd/MM/yyyy hh:mm a"
												)} `}
											</h5>
										</Row>
										<Row style={{ textAlign: "center" }}>
											<i className="uil uil-arrow-down fs-24 mb-2" />
										</Row>
										<Row>
											<h5 style={{ textAlign: "center" }}>
												{!one.logout
													? "Still active"
													: `${format(
															new Date(one.logout),
															"EEE dd/MM/yyyy hh:mm a"
													  )} `}
											</h5>
										</Row>
									</Card.Body>
								</Card>
							);
						})}
					</Row>
					<Row>
						<Pagination
							count={totalPages}
							page={page}
							onChange={(e, value) => {
								e.preventDefault();
								if (page !== value) {
									setPage(value);
									setReload(!reload);
								}
							}}
						/>
					</Row>
				</Col>
			)}
		</>
	);
};

export default LogInOut;
